$(document).ready(function() {
  var bigPhone = 768;
  var dropdownLink = $('[data-behavior="nav-toggle"]');
  var span = dropdownLink.children('span');
  var nav = dropdownLink.siblings('aside');
  dropdownLink.click(function() {
    var link = $(this);
    span.toggleClass('open');
    nav.slideToggle(200);
  });

  $(window).resize(function() {
    if ( $(this).width() > bigPhone ) {
      span.removeClass('open');
      nav.removeAttr('style');
    }
  });
});
