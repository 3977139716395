/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://coffeescript.org/

let audio = null;

$(document).ready(function() {

    let table;
    $('.play_recording').click(function() {
        const url = $(this).closest('tr').find('td.media_file_path').text();
        return playRecording(url);
    });

    $('.stop_recording').click(() => stopRecording());

    return table = $('#recordtable').DataTable({
      paging: true,
      ordering: true,
      "columnDefs": [{
        "targets": [2], "orderable": false
      }],
      language: {
        "sEmptyTable":     I18n.t("shared.datatables.empty_table"),
        "sInfo":           I18n.t("shared.datatables.info"),
        "sInfoEmpty":      I18n.t("shared.datatables.info_empty"),
        "sInfoFiltered":   I18n.t("shared.datatables.info_filtered"),
        "sInfoPostFix":    "",
        "sInfoThousands":  ",",
        "sLengthMenu":     I18n.t("shared.datatables.length_menu"),
        "sLoadingRecords": I18n.t("shared.datatables.loading_records"),
        "sProcessing":     I18n.t("shared.datatables.processing"),
        "sSearch":         I18n.t("shared.datatables.search") + ":",
        "sZeroRecords":    I18n.t("shared.datatables.zero_records"),
        "oPaginate": {
          "sFirst":    I18n.t("shared.datatables.first"),
          "sLast":     I18n.t("shared.datatables.last"),
          "sNext":     I18n.t("shared.datatables.next"),
          "sPrevious": I18n.t("shared.datatables.previous")
        },
        "oAria": {
          "sSortAscending":  I18n.t("shared.datatables.sort_ascending") + ": ",
          "sSortDescending": I18n.t("shared.datatables.sort_descending") + ": "
        }
      }
    });
});
//
// translationData = {
//   "sEmptyTable":     I18n.t("shared.datatables.empty_table"),
//   "sInfo":           I18n.t("shared.datatables.info"),
//   "sInfoEmpty":      I18n.t("shared.datatables.info_empty"),
//   "sInfoFiltered":   I18n.t("shared.datatables.info_filtered"),
//   "sInfoPostFix":    "",
//   "sInfoThousands":  ",",
//   "sLengthMenu":     I18n.t("shared.datatables.length_menu"),
//   "sLoadingRecords": I18n.t("shared.datatables.loading_records"),
//   "sProcessing":     I18n.t("shared.datatables.processing"),
//   "sSearch":         I18n.t("shared.datatables.search") + ":",
//   "sZeroRecords":    I18n.t("shared.datatables.zero_records"),
//   "oPaginate": {
//     "sFirst":    I18n.t("shared.datatables.first"),
//     "sLast":     I18n.t("shared.datatables.last"),
//     "sNext":     I18n.t("shared.datatables.next"),
//     "sPrevious": I18n.t("shared.datatables.previous")
//   },
//   "oAria": {
//     "sSortAscending":  I18n.t("shared.datatables.sort_ascending") + ": ",
//     "sSortDescending": I18n.t("shared.datatables.sort_descending") + ": "
//   }
// }

var playRecording = function(url) {
    // sound = new Howl({
    //     urls: [url]
    // })

    // sound.play
    audio = new Audio(url);
    return audio.play();
  };

var stopRecording = function() {
    if (audio !== null) {
        audio.pause();
        return audio.currentTime = 0;
      }
  };
