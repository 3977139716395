/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://coffeescript.org/
$(document).ready(function() {
  //  return if page.controller() == 'admins'
    if ($('#org_id').val() && ($('.dashboard-app-change').length > 0)) {
        loadDashboard($('#org_id').val(),$('#application_id').val());
    }

    $('.app_op').change(function() {
        const arr = $(this).attr("id").split("_");
        const endpointId = arr[1];
        if (!$(this).val()) {
            $('#version_' + endpointId).empty();
            return;
        }
        return changeVersion($(this).val(), endpointId);
    });

    $("#skills_selected_id").click(function() {
        $("#skill-selection").val($(this).val());
        if ($(this).val() !== null) {
            return $.ajax({
                type: "GET",
                url:    "/skills/lookup_competency?agent_id=" + $("#agent-id").val() + "&skill_id=" + $(this).val(),
                datatype: "json",
                async:   true,
                success(data, textStatus, jqXHR) {
                  return $("#skill-competency").val(data.competency);
              },

                error(jqXHR, textStatus, errorThrown) {}
            });
        }
    });

    $('#btn-update').click(function() {});

    $('.dashboard-app-change').change(function() {
          $('#application_id').val( $(this).val());
          return loadDashboard($('#org_id').val(),$(this).val());
    });



    $('.btn-sv').click(function() {
        const arr = $(this).attr("id").split("_");
        const app_id = $('#application_' + arr[1]).val();
        const ver_id = $('#version_' + arr[1]).val();
        const description = $('#description_' + arr[1]).val();
        return saveNumber(arr[1],app_id,ver_id,description);
    });

    $('.btn-dl').click(function() {
        const arr = $(this).attr("id").split("_");
        const ep_id_raw = "ep_" + arr[1];
        const unprocessed_number = document.getElementById(ep_id_raw).getElementsByClassName("cell cell-15")[0].innerHTML;
        const processed_number = process_number(unprocessed_number);
        if (window.confirm(I18n.t("organizations.numbers.are_you_sure_confirmation"))) {
          return deleteNumber(arr[1],processed_number);
      }
    });

    $('#post_org_id').change(function() {
        return changeApplication($(this).val());
    });

    $('[data-behavior="submit-create-folder"]').click(function() {
        const foldername = $('#foldername').val();
        return createFolder(foldername);
    });


    $('[data-behavior="show-hidden-form"]').click(function() {
      $('[data-class="hidden-form"]').fadeIn();
      return $('[data-class="hidden-form"]').find('input[type=text]').focus().val("");
    });

    $('#file_').change(() => $('#btnSubmit').prop("disabled", false));

    const table = $('#usertable').DataTable({
      paging: true,
      ordering: true,
      "columnDefs": [{
        "targets":[3], "orderable": false
      }],
      language: {
        "sEmptyTable":     I18n.t("shared.datatables.empty_table"),
        "sInfo":           I18n.t("shared.datatables.info"),
        "sInfoEmpty":      I18n.t("shared.datatables.info_empty"),
        "sInfoFiltered":   I18n.t("shared.datatables.info_filtered"),
        "sInfoPostFix":    "",
        "sInfoThousands":  ",",
        "sLengthMenu":     I18n.t("shared.datatables.length_menu"),
        "sLoadingRecords": I18n.t("shared.datatables.loading_records"),
        "sProcessing":     I18n.t("shared.datatables.processing"),
        "sSearch":         I18n.t("shared.datatables.search") + ":",
        "sZeroRecords":    I18n.t("shared.datatables.zero_records"),
        "oPaginate": {
          "sFirst":    I18n.t("shared.datatables.first"),
          "sLast":     I18n.t("shared.datatables.last"),
          "sNext":     I18n.t("shared.datatables.next"),
          "sPrevious": I18n.t("shared.datatables.previous")
        },
        "oAria": {
          "sSortAscending":  I18n.t("shared.datatables.sort_ascending") + ": ",
          "sSortDescending": I18n.t("shared.datatables.sort_descending") + ": "
        }
      }
    });

    $('#customNumberButton').click(function(e) {
      e.preventDefault();
      $('#new_number').toggle();
      return $('#custom_number_form').toggle();
    });

    return $('#tropoNumberButton').click(function(e) {
      e.preventDefault();
      $('#new_number').toggle();
      return $('#custom_number_form').toggle();
    });
});

// translationData = {
//   "sEmptyTable":     I18n.t("shared.datatables.empty_table"),
//   "sInfo":           I18n.t("shared.datatables.info"),
//   "sInfoEmpty":      I18n.t("shared.datatables.info_empty"),
//   "sInfoFiltered":   I18n.t("shared.datatables.info_filtered"),
//   "sInfoPostFix":    "",
//   "sInfoThousands":  ",",
//   "sLengthMenu":     I18n.t("shared.datatables.length_menu"),
//   "sLoadingRecords": I18n.t("shared.datatables.loading_records"),
//   "sProcessing":     I18n.t("shared.datatables.processing"),
//   "sSearch":         I18n.t("shared.datatables.search") + ":",
//   "sZeroRecords":    I18n.t("shared.datatables.zero_records"),
//   "oPaginate": {
//     "sFirst":    I18n.t("shared.datatables.first"),
//     "sLast":     I18n.t("shared.datatables.last"),
//     "sNext":     I18n.t("shared.datatables.next"),
//     "sPrevious": I18n.t("shared.datatables.previous")
//   },
//   "oAria": {
//     "sSortAscending":  I18n.t("shared.datatables.sort_ascending") + ": ",
//     "sSortDescending": I18n.t("shared.datatables.sort_descending") + ": "
//   }
// }

var process_number = function(number_to_process) {
  //remove white space
  number_to_process = number_to_process.replace(/\s+/g, '');
  //remove '(' and ')'
  number_to_process = number_to_process.replace("(", '').replace(")", '');
  //remove -
  number_to_process = number_to_process.replace("-", '');
  return number_to_process;
};


const getOrgId = () => $("input[type=hidden]#organization_id").val();

var createFolder = function(foldername) {
    $.ajax({
        type: "GET",
        url: "prompt_repo/new_folder",
        data: {foldername},
        async: true,
        success(data, textStatus, jqXHR) {
            location.reload();
            return console.log("Successfully created folder");
        },
        error(jqXHR, textStatus, errorThrown) {
            console.log(errorThrown);
            return location.reload();
        }
    });
    return false;
};

var changeVersion = function(appId, epId) {
  //  $(".content").spin();
    $.ajax({
        type: "GET",
        url:    "/organizations/" + getOrgId() + "/applications/" + appId + "/get_versions",
        datatype:"json",
        async:   true,
        success(maxVersion, textStatus, jqXHR) {
        //    $(".content").spin(false);
            $('#version_' + epId).empty();
            return __range__(maxVersion, 0, false).map((i) =>
              i === maxVersion ?
                $('#version_' + epId).append('<option value="' + i + '">' + i + " (latest)</option>")
              :
                $('#version_' + epId).append('<option value="' + i + '">' + i + "</option>"));
        },

        error(jqXHR, textStatus, errorThrown) {
        //    $(".content").spin(false);
            return $('#version_' + epId).empty();
        }
    });

    return false;
};


var changeApplication = function(orgId) {
  //  $(".content").spin();
    $.ajax({
        type: "GET",
        url:    "/organizations/" + orgId + "/get_apps",
        datatype:"json",
        async:   true,
        success(data, textStatus, jqXHR) {
        //    $(".content").spin(false);
            $('#post_app_id').empty();
            return Array.from(data).map((value, i) =>
              $('#post_app_id').append('<option value="' + data[i].id + '">' + data[i].name + "</option>"));
        },

        error(jqXHR, textStatus, errorThrown) {
        //    $(".content").spin(false);
            return $('#version_' + epId).empty();
        }
    });

    return false;
};

var deleteNumber = function(ep_id,phone_number) {
  //  $(".content").spin();
    $.ajax({
          type: "DELETE",
          url:   "/organizations/" + ep_id + "/signalwire_delete/" + phone_number,
          datatype:"json",
          async:   true,
          success(data, textStatus, jqXHR) {
          //    $(".content").spin(false);
              $('#ep_' + ep_id).hide();
              return alert(I18n.t("organizations.numbers.number_successfully_deleted_alert"));
          },
          error(jqXHR, textStatus, errorThrown) {}
    });
        //      $(".content").spin(false);

    return false;
};



var saveNumber = function(ep_id, application_id, version, description) {
  //  $(".content").spin();
    $.ajax({
        type: "POST",
        url:    "/entry_points/" + ep_id + '/update_call_flow.json',
        datatype: "json",
        async:   true,
        data: { application_id, version, description },
        success(data, textStatus, jqXHR) {
      //      $(".content").spin(false);
            return alert(I18n.t("organizations.numbers.number_successfully_saved_alert"));
        },

        error(jqXHR, textStatus, errorThrown) {}
    });
      //    $(".content").spin(false);


    return false;
};



// Utility to retrieve query parameters from URL.
const getParameterByName = function(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    const results = regex.exec(location.search);
    if ((results == null)) { return ''; }
    return decodeURIComponent(results[1].replace(/\+/g, ' '));
};

function __range__(left, right, inclusive) {
  let range = [];
  let ascending = left < right;
  let end = !inclusive ? right : ascending ? right + 1 : right - 1;
  for (let i = left; ascending ? i < end : i > end; ascending ? i++ : i--) {
    range.push(i);
  }
  return range;
}