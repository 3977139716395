var realTimeStatInterval;
var lastOrganizationId;
var lastApplicationId;
var lastDateRange;

window.loadDashboard = function loadDashboard(organization_id, application_id) {
  if (realTimeStatInterval) {
    clearInterval(realTimeStatInterval);
  }
  if ( $( 'input[name="daterange"]' ).length ) {
    $('input[name="daterange"]').daterangepicker();
    var date_range = $('#datepicker').val();
  }

  lastOrganizationId = organization_id;
  lastApplicationId = application_id;
  lastDateRange = date_range;

  if (organization_id != 620) {
    loadCallHistory(organization_id, application_id, date_range);
  }
  loadTextHistory(organization_id, application_id, date_range);
  loadVoicemailHistory(organization_id, application_id, date_range);

  if (organization_id != 620) {
    loadDashboardApp(lastOrganizationId, lastApplicationId, lastDateRange);
    loadRealTimeStats(organization_id, application_id, date_range);
    loadAgentStats(organization_id, application_id, date_range);
    loadSkillStats(organization_id, application_id, date_range);
    realTimeStatInterval = setInterval(function() {
      loadDashboardApp(lastOrganizationId, lastApplicationId, lastDateRange, true);
      loadRealTimeStats(organization_id, application_id, date_range);
      loadAgentStats(organization_id, application_id, date_range);
      loadSkillStats(organization_id, application_id, date_range);
    }, 10000);
  }


}


function loadRealTimeStats(organization_id, application_id, date_range) {
  console.log("Loading realtime stats");
  $.get("/reports/real_time_stats?organization_id="+organization_id+"&application_id="+application_id+"&daterange="+date_range, function(data) {
    console.log("Recieved realtime stats: " + JSON.stringify(data))
    $("#active_calls_value").html(data.total_active_calls);
    $("#handled_today_value").html(data.handled_today_calls);
    $("#transferred_today_value").html(data.total_transferred_today);
  });
}
function refreshMasonry() {
  $('[data-behavior="masonry-container"]').masonry({
      itemSelector: '[data-behavior="masonry-item"]',
      gutter: 32
    });
}

function loadCallHistory(org_id,app_id,daterange){
  $('#call_history_list').html("");
  $('#call_history_footer').html("");
  $.get("/reports/get_call_h?organization_id="+org_id+"&application_id="+app_id+"&daterange="+daterange, function( data ) {
//    $('#call_history_list').append('<div class="list-box-title">Call History</div>');
//    $('#call_history_list').append('<ul id="call_hist_list">');
      var datal = 5;
      if (data.length < 5){
        datal = data.length
      }
      for (var i = 0; i < datal; i++) {
        $('#call_history_list').append('<li><div class="list-value">' + data[i][0] + '</div><div class="sublabel">' + data[i][1] + ' - ' + data[i][2]);
      }
      if (app_id!=0)
      {
        $('#call_history_footer').append('<a class="aux-link" href="/organizations/' + org_id + '/applications/' + app_id + '/calls" >' + I18n.t("organizations.shared.list_box.view_all_link") + '</a>');
      } else {
        $('#call_history_footer').append('<a class="aux-link" href="/organizations/' + org_id + '/calls" >' + I18n.t("organizations.shared.list_box.view_all_link") + '</a>');
      }
refreshMasonry();
    });
}

function loadTextHistory(org_id,app_id,daterange){
  $('#text_history_list').html("");
  $('#text_history_footer').html("");
  $.get("/reports/get_text_h?organization_id="+org_id+"&application_id="+app_id+"&daterange="+daterange, function( data ) {
//    $('#call_history_list').append('<div class="list-box-title">Call History</div>');
//    $('#call_history_list').append('<ul id="call_hist_list">');
      var datal = 10;
      if (data.length < 10){
        datal = data.length
      }
      for (var i = 0; i < datal; i++) {
        $('#text_history_list').append('<li><div class="list-value">' + data[i][0] + '</div><div class="sublabel">' + data[i][1] + ' - ' + data[i][2]);
      }
      if (app_id!=0)
      {
        $('#text_history_footer').append('<a class="aux-link" href="/organizations/' + org_id + '/applications/' + app_id + '/incoming_text_messages" >' + I18n.t("organizations.shared.list_box.view_all_link") + '</a>');
      } else {
        $('#text_history_footer').append('<a class="aux-link" href="/organizations/' + org_id + '/incoming_text_messages" >' + I18n.t("organizations.shared.list_box.view_all_link") + '</a>');
      }
refreshMasonry();
    });
}

function loadVoicemailHistory(org_id,app_id,daterange){
  $('#voicemail_history_list').html("");
  $('#voicemail_history_footer').html("");
  $.get("/reports/get_voicemail_h?organization_id="+org_id+"&application_id="+app_id+"&daterange="+daterange, function( data ) {
//    $('#call_history_list').append('<div class="list-box-title">Call History</div>');
//    $('#call_history_list').append('<ul id="call_hist_list">');
      var datal = 5;
      if (data.length < 5){
        datal = data.length
      }
      for (var i = data.length - 1; i > data.length - 1 - datal; i--) {
        $('#voicemail_history_list').append('<li><div class="list-value">' + data[i][0] + '</div><div class="sublabel">' + data[i][1] + ' - ' + data[i][2]);
      }
      if (app_id!=0)
      {
        $('#voicemail_history_footer').append('<a class="aux-link" href="/organizations/' + org_id + '/applications/' + app_id + '/recordings_repo" >' + I18n.t("organizations.shared.list_box.view_all_link") + '</a>');
      } else {
        $('#voicemail_history_footer').append('<a class="aux-link" href="/organizations/' + org_id + '/recordings_repo" >' + I18n.t("organizations.shared.list_box.view_all_link") + '</a>');
      }
      refreshMasonry();

    });
}

function loadAgentStats(org_id,app_id,daterange){
  $.get("/reports/get_agent_s?organization_id="+org_id+"&application_id="+app_id+"&daterange="+daterange, function( data ) {
    for (var i = 0; i < data.length; i++) {
      $('.agent-cht-'+data[i][1]).html(data[i][2]);
      $('.agent-aht-'+data[i][1]).html(data[i][3]);
      $('.agent-cst-'+data[i][1]).html(data[i][4]);
    }
    refreshMasonry();

    });
}

function loadSkillStats(org_id,app_id,daterange){
  $.get("/reports/get_skill_s?organization_id="+org_id+"&application_id="+app_id+"&daterange="+daterange, function( data ) {
      for (var i = 0; i < data.length; i++) {
        $('.skill-cht-'+data[i][1]).html(data[i][2]);
        $('.skill-ciq-'+data[i][1]).html(data[i][3]);
        $('.skill-lcq-'+data[i][1]).html(data[i][4]);
        $('.skill-aht-'+data[i][1]).html(data[i][5]);
      }
      refreshMasonry();

    });
}

function loadDashboardApp(org_id,app_id,daterange, suppressSpinner){
  lastOrganizationId = org_id;
  lastApplicationId = app_id;
  lastDateRange = daterange;

  if (!suppressSpinner) {
  //  $(".org-chart").spin();
  }
  //$('.org-chart').html("");
  var options = {
    height: 300,
    lineSmooth: false,
    showLine: false,
    showPoint: false,
    low: 0,
    showArea: true,
    scaleMinSpace: 32,

    axisY: {
      onlyInteger: true,
      offset: 40,
    },
    axisX: {
      showGrid: false,
    }
  };
  xaxis = [];


  $.get("/reports/caller_hangups_per_step?organization_id="+org_id+"&application_id="+app_id+"&daterange="+daterange, function( data ) {
//    $(".org-abandons-chart").spin(false);

    if (data.labels.length > 0) {
      $('#org-abandons-chart').removeClass('empty');

      new Chartist.Bar('[data-id="org-abandons-chart"]', {
        labels: data.labels,
        series: data.values,
      }, {

        height: 50 * data.labels.length,
        reverseData: true,
        horizontalBars: true,

        axisY: {
          offset: 100,
          showGrid: false
        },
        axisX: {
          showGrid: true,
          onlyInteger: true
        }
      });

    } else {
        $('#org-abandons-chart').addClass('empty');
        $('#org-abandons-chart').html("<h2>There were no caller hangups in the selected time range.</h2>");
      }
  });

  $.get("/reports/get_cv_xaxis?daterange=" + daterange, function( xax ) {
    $.get("/reports/get_cv_yaxis?organization_id="+org_id+"&application_id="+app_id+"&daterange="+daterange, function( yax ) {
      if(yax != 0){
        if (!yax instanceof Array) {
          yax = [yax];
        }

//        $(".org-chart").spin(false);
        var chart = new Chartist.Line('[data-id="org-chart"]', {
          labels: xax,
          series: yax
        }, options, [
          ['screen and (max-width: 400px)', {
            axisX: {
              labelInterpolationFnc: function(value) {
                return value[0];
              }
            }
          }]
        ]);
      } else {
        $('.org-chart').html("<h1>There is currently no data for this application.</h1>");
      }
    });
  });
}
