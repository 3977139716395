$(window).on('load', function() {



  // MENU ICON / MOBILE MENU
  $('a.menu-button').click(function(e) {
    var icon = $(this);
    var nav = $('header nav');
    clearTimeout($(this).data('menuTime'));
    var menuTime = setTimeout(function() {
      if (nav.is(':visible')) {
        icon.removeClass('open');
        nav.slideUp();
      } else {
        nav.slideDown();
        icon.addClass('open');
      }
    }, 400);
    $(this).data('menuTime', menuTime);
    e.preventDefault();
  });

  // MENU RESET
  $(window).resize(function() {
    if ($(window).width() > 680) {
      $('header nav').css({display:''});
      $('a.menu-button').removeClass('open');
    }
  }); // END WINDOW RESIZE // .trigger('resize');?

}); // END WINDOW LOAD

function usageInput(month,year,org) {
  window.location = "http://ivr.cloverhound.com/usage?month="+month+"&year="+year+"&org="+org;
}
