/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
$(document).ready(function() {
  $('.billingDateSelector').on('change', function() {
    const orgId     = $('input[name="organization_id"]').val();
    const monthYear = $(this).val().split("_");
    const month     = parseInt(monthYear[0]);
    const year      = parseInt(monthYear[1]);

    return $.ajax({
      type: "POST",
      url: "/organizations/" + orgId + "/billing_month",
      data: { month, year },
      success(data, textStatus, jqXHR) {
        $('#minutesLeft').text(data["minutes_left"]);
        $('#minutesUsed').text(data["minutes_used"]);
        return $('#minutesOver').text(data["minutes_over"]);
      }
    });
  });

  $('#add-overage-credit').on('click', function(e) {
    e.preventDefault();

    $('#error_explanation').html('');

    const organization_id = $('input[name="organization_id"]').val();
    let amount = $('input#amount').val();
    amount = amount.replace(/\$/g, '').replace(/\,/g, '');
    amount = parseFloat(amount);

    if (isNaN(amount)) {
      return $('#error_explanation').html('<p>Please enter a valid amount in USD ($).</p>');
    } else if (amount < 0.00) {
      return $('#error_explanation').html('<p>Credit amount must be at least $0.</p>');
    } else {
      amount = amount * 100; // Needs to be an integer!

      return $.ajax({
        type: "POST",
        url:    "/organizations/" + organization_id + '/add_overage_credit',
        datatype: "json",
        async:   true,
        data: { organization_id, amount },
        success(data) {
          return $(".content").spin(false);
        },
        error(jqXHR, textStatus, errorThrown) {
          return $(".content").spin(false);
        }
      });
    }
  });

  return $('.enterprise-contact-button').on('click', function(e) {
    e.preventDefault();
    return window.location.replace('https://cloverhound.com/contact/');
  });
});
