
$(function() {

    if ($('.direct-upload').length > 0) {
    $.get( "/pspost?org_id="+$( "#org_id" ).val()+"&prefix="+getUrlParameter('prefix'), function( s3params ) {
      $( "#progressbar" ).progressbar({
  value: false
});

  $( "#progressbar" ).css('background', 'green').css('display', 'none')

      $('.direct-upload').find("input:file").each(function(i, elem) {
        var fileInput    = $(elem);
        var form         = $(fileInput.parents('form:first'));
        var submitButton = form.find('input[type="submit"]');
        var progressBar  = $("<div class='meter'></div>");
        var barContainer = $("<div class='progress-bar'></div>").append(progressBar);
        fileInput.after(barContainer);

        fileInput.fileupload({
          fileInput:       fileInput,
          url:             "http://" + s3params.url.host,
          type:            'POST',
          autoUpload:       false,
          formData:         s3params.fields,
          paramName:        'file', // S3 does not like nested name fields i.e. name="user[avatar_url]"
          dataType:         'XML',  // S3 returns XML if success_action_status is set to 201
          replaceFileInput: false,
          progressall: function (e, data) {
            var progress = parseInt(data.loaded / data.total * 100, 10);
            $( "#progressbar" ).progressbar( "option", "value", progress );
          },
          add: function(e, data) {
            $( "#btnSubmit").unbind( "click" );
            $("#btnSubmit").click(function () {
            var acceptFileTypes = /(mp3|wav)$/i;
            if(data.originalFiles[0]['type'].length && !acceptFileTypes.test(data.originalFiles[0]['type'])) {
            alert('Not an accepted file type');
            }
            else if(data.originalFiles[0]['size'].length && data.originalFiles[0]['size'] > 5000000) {
            alert('Filesize is too big');
            } else {
            data.submit();
            }
            $( "#btnSubmit").unbind( "click" );
            });
},
          start: function (e) {
            submitButton.prop('disabled', true);
            barContainer.css('display', 'block');
            $( "#progressbar" ).css('background', 'green').css('display', 'block')
          },
          done: function(e, data) {
            location.reload();
          },
          fail: function(e, data) {
            submitButton.prop('disabled', false);

            progressBar.
              addClass('failed').
              text("Failed");
          }
        });
      });
    }, 'json');
}
});

var getUrlParameter = function getUrlParameter(sParam) {
    var sPageURL = decodeURIComponent(window.location.search.substring(1)),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : sParameterName[1];
        }
    }
};
