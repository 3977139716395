$(document).ready( function(){
  var scrollTo = $('[data-behavior="scroll-to"]');

  scrollTo.click(function(e){
    var target = $(this).attr("data-target");
    $('html, body').animate({
      scrollTop: $('[data-structure="'+target+'"]').offset().top
    }, 380);
    e.preventDefault();
  });
});