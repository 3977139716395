/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://coffeescript.org/
$(document).ready(function() {
    console.log($('#admin_org_id').val());
    if ($('#admin_org_id').val() && ($('.admin-dashboard-app-change').length > 0)) {
        console.log("running...");
    }
     // loadAdminDashboard($('#admin_org_id').val());

    return $('.admin-dashboard-app-change').change(function() {
          return $('#admin_org_id').val( $(this).val());
    });
});
    // loadAdminDashboard($('#admin_org_id').val());
