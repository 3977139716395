/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

console.log('Hello World from Webpacker')
import PropTypes from 'prop-types'
import $ from 'jquery'
window.jQuery = $; window.$ = $;
import I18n from 'i18n-js'
window.I18n = I18n
window.I18n.defaultLocale = "en"
window.I18n.locale = "en"
import * as Highcharts from 'highcharts';
window.Highcharts = Highcharts;
import Chartist from 'chartist';
window.Chartist = Chartist;
//require('jquery_ujs') // special case //unneeded as of rails 5.1
require('webpack-jquery-ui');
require('webpack-jquery-ui/css');
//require('webpack-jquery-ui/widgets') // npm install webpack-jquery-ui
require('../jquery.spin')
require('../jquery.scrollTo-min')
require('../jquery.cookie')
require('../jquery.watermark.min')
require('../jquery.ui.widget.min.js')
require('../jquery.fileupload')
require('bootstrap')
require('../front-end')

require('../mobile-menu.js')
require('../init')
require('../admins-chart')
require('../admins')

require('../applications')
//require('coffee-loader!./../audio_folders.coffee') //coffee
require('../aws-upload')
require('../billing')

require('chartist')
require('../daterangepicker.jQuery')
//require('../dialer_campaign')
require('../dismiss-message')
require('../documentation/dropdown')
require('../documentation/sidebarmenu')
require('howler')

require('../incoming_text_messages')
require('../marketing/match-height-config')
require('../marketing/scroll-to')
require('../marketing/tabs')
require('jquery-match-height')
require('../masonry')
require('../masonry.pkgd.min')
//require('../javascripts/moment.min')
require('../organizations')
require('../organizations-chart')
require('../recordings')
require('../reports')
require('../sortable.min.js')
require('../user-menu')
require('../user-settings')
// Add DataTables jQuery plugin
require('imports-loader?define=>false!datatables.net')(window, $)
require('imports-loader?define=>false!datatables.net-select')(window, $)
import {Spinner} from 'spin.js';
// Load datatables styles
import 'datatables.net-dt/css/jquery.dataTables.css'
