/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://coffeescript.org/

//window.Highcharts = require('highcharts')

// Load module after Highcharts is loaded
//require('highcharts/modules/exporting')(window.Highcharts)

$(document).ready(() => $('#report-selector').change(function() {
  const report = $(this).val();
  return window.location.replace(`${window.location.pathname}?report=${report}`);
}));
