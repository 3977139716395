/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS202: Simplify dynamic range loops
 * DS205: Consider reworking code to avoid use of IIFEs
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.



$(document).ready(function() {

  let appParam;
  const currentPath = window.location.pathname.split('/');
  if((currentPath[currentPath.length - 2] === "number") && (currentPath[currentPath.length - 1] === "new")) {
    getNumbers();
  }

  $('#addNumberButton').click(function(event) {
    event.preventDefault;
    addNumber(getOrgId(), getRegion());
    return false;
  });

  $('#addEntryPointButton').click(function(event) {
    event.preventDefault;
    addEntryPoint(getOrgId(), getRegion(), getName(getRegion()), getBot());
    return false;
  });

  $('#tropoSaveButton').click(function(event) {
    event.preventDefault;
    console.log("NUMBER FROM GET NUMBER " + getNumber());
    return provision_number(getOrgId(), getNumber());
  });

  $('#deleteApplication').click(event => event.preventDefault());

  $('#appDeleteName').on('keyup', function(event) {
    if (event.target.value === $('#appDeleteName').data('app')) {
      return $('#deleteAppButton').removeAttr('disabled');
    } else if (event.target.value !== $('#appDeleteName').data('app')) {
      return $('#deleteAppButton').attr('disabled', true);
    }
  });

  $('#deleteAppButton').on("click", function(event) {
    event.preventDefault();
    console.log($('#deleteAppButton').is(':disabled'));
    if ($('#deleteAppButton').is(':disabled') === false) {
      return deleteApplication(getOrgId(), getAppId());
    }
  });

  // $('#timezone').change () ->
  //   setTimeZone $(this).val()

  $('#application_voice_language').change(function() {
    return setVoiceLanguage($(this).val());
  });

  $('.btn-sigwire').click(function() {
    const area_code = document.getElementById("area_code").value;
    return sig_wire_pop(area_code);
  });

  // $('#application_voice').change () ->
  //   setVoice $(this).val()
  //
  //
  // $('#application_language').change () ->
  //   setLanguage $(this).val()
  //
  // $('#application_voice').change () ->
  //   setVoice $(this).val()
  //
  // $('#application_language').change () ->
  //   setLanguage $(this).val()

  let callLogsTable = $('#session-logs').DataTable({
      processing: false,
      serverSide: true,
      ajax: {
        "url": "/calls/" + $('#session-logs').data('source') + "/session_logs"
      },
      paging: true,
      pageLength: 100,
      ordering: true,
      "columnDefs": [{
        "orderable": false,
        "targets": -1,
        "className": "cell-data cell-data-last",
        "defaultContent": "<a class='aux-link'>" + I18n.t("calls.index.show_link") + "</a>"
      }, {
        "className": "cell-data",
        "targets": "_all"
      }, {
        "targets": [1, 2, 3],
        "orderable": false
      }],
      lengthChange: false,
      language: {
        "sEmptyTable":     I18n.t("shared.datatables.empty_table"),
        "sInfo":           I18n.t("shared.datatables.info"),
        "sInfoEmpty":      I18n.t("shared.datatables.info_empty"),
        "sInfoFiltered":   I18n.t("shared.datatables.info_filtered"),
        "sInfoPostFix":    "",
        "sInfoThousands":  ",",
        "sLengthMenu":     I18n.t("shared.datatables.length_menu"),
        "sLoadingRecords": I18n.t("shared.datatables.loading_records"),
        "sProcessing":     I18n.t("shared.datatables.processing"),
        "sSearch":         I18n.t("shared.datatables.search") + ":",
        "sZeroRecords":    I18n.t("shared.datatables.zero_records"),
        "oPaginate": {
          "sFirst":    I18n.t("shared.datatables.first"),
          "sLast":     I18n.t("shared.datatables.last"),
          "sNext":     I18n.t("shared.datatables.next"),
          "sPrevious": I18n.t("shared.datatables.previous")
        },
        "oAria": {
          "sSortAscending":  I18n.t("shared.datatables.sort_ascending") + ": ",
          "sSortDescending": I18n.t("shared.datatables.sort_descending") + ": "
        }
      }
      //"bJQueryUI": true
    });
    //$('div.dataTables_filter input').removeClass('input-sm')

  $('#session-logs tbody').on('click', 'a', function() {
    const appId = $('#session-logs').data('app-id');
    const callId = $(this).closest('tr')[0].id;
    const orgId = $('#session-logs').data('source');
    window.location.href = "/organizations/" + orgId + "/bot_sessions/" + callId;
    if (appId !== "") { window.location.href = "/organizations/" + orgId + "/applications/" + appId + "/bot_sessions/" + callId; }
  });

  $('#application_id').value;
  if (getAppId() !== undefined) {
    appParam = "?application_id=" + getAppId();
  } else {
    appParam = "";
  }

  callLogsTable = $('#call-logs').DataTable({
      processing: false,
      serverSide: true,
      ajax: {
        "url": "/calls/" + $('#call-logs').data('source') + "/logs" + appParam
      },
      paging: true,
      pageLength: 100,
      ordering: true,
      sorting: [[0, 'desc']],//, [1, 'desc']],
      "columnDefs": [{
        "orderable": false,
        "targets": -1,
        "className": "cell-data cell-data-last",
        "defaultContent": "<a class='aux-link'>"  + I18n.t("calls.index.show_link") + "</a>"
      }, {
        "className": "cell-data",
        "targets": "_all"
      }, {
        "targets": [1, 2, 3],
        "orderable": false
      }],

      lengthChange: false,
      language: {
        "sEmptyTable":     I18n.t("shared.datatables.empty_table"),
        "sInfo":           I18n.t("shared.datatables.info"),
        "sInfoEmpty":      I18n.t("shared.datatables.info_empty"),
        "sInfoFiltered":   I18n.t("shared.datatables.info_filtered"),
        "sInfoPostFix":    "",
        "sInfoThousands":  ",",
        "sLengthMenu":     I18n.t("shared.datatables.length_menu"),
        "sLoadingRecords": I18n.t("shared.datatables.loading_records"),
        "sProcessing":     I18n.t("shared.datatables.processing"),
        "sSearch":         I18n.t("shared.datatables.search") + ":",
        "sZeroRecords":    I18n.t("shared.datatables.zero_records"),
        "oPaginate": {
          "sFirst":    I18n.t("shared.datatables.first"),
          "sLast":     I18n.t("shared.datatables.last"),
          "sNext":     I18n.t("shared.datatables.next"),
          "sPrevious": I18n.t("shared.datatables.previous")
        },
        "oAria": {
          "sSortAscending":  I18n.t("shared.datatables.sort_ascending") + ": ",
          "sSortDescending": I18n.t("shared.datatables.sort_descending") + ": "
        }
      }
      //"bJQueryUI": true
    });
    //$('div.dataTables_filter input').removeClass('input-sm')

  $('#call-logs tbody').on('click', 'a', function() {
    const appId = $('#call-logs').data('app-id');
    const callId = $(this).closest('tr')[0].id;
    const orgId = $('#call-logs').data('source');
    window.location.href = "/organizations/" + orgId + "/calls/" + callId;
    if (appId !== "") { window.location.href = "/organizations/" + orgId + "/applications/" + appId + "/calls/" + callId; }
  });

  $('#details-table').DataTable({
    paging: true,
    ordering: true,
    "columnDefs": [{
      "orderable": false
    }],
    language: {
      "sEmptyTable":     I18n.t("shared.datatables.empty_table"),
      "sInfo":           I18n.t("shared.datatables.info"),
      "sInfoEmpty":      I18n.t("shared.datatables.info_empty"),
      "sInfoFiltered":   I18n.t("shared.datatables.info_filtered"),
      "sInfoPostFix":    "",
      "sInfoThousands":  ",",
      "sLengthMenu":     I18n.t("shared.datatables.length_menu"),
      "sLoadingRecords": I18n.t("shared.datatables.loading_records"),
      "sProcessing":     I18n.t("shared.datatables.processing"),
      "sSearch":         I18n.t("shared.datatables.search") + ":",
      "sZeroRecords":    I18n.t("shared.datatables.zero_records"),
      "oPaginate": {
        "sFirst":    I18n.t("shared.datatables.first"),
        "sLast":     I18n.t("shared.datatables.last"),
        "sNext":     I18n.t("shared.datatables.next"),
        "sPrevious": I18n.t("shared.datatables.previous")
      },
      "oAria": {
        "sSortAscending":  I18n.t("shared.datatables.sort_ascending") + ": ",
        "sSortDescending": I18n.t("shared.datatables.sort_descending") + ": "
      }
    }
  });

  $('#summary-table').DataTable({
    paging: true,
    ordering: true,
    "columnDefs": [{
      "orderable": false
    }],
    sorting: [[0, 'desc'], [1, 'desc']],
    pageLength: 100,
    lengthChange: false,
    language: {
      "sEmptyTable":     I18n.t("shared.datatables.empty_table"),
      "sInfo":           I18n.t("shared.datatables.info"),
      "sInfoEmpty":      I18n.t("shared.datatables.info_empty"),
      "sInfoFiltered":   I18n.t("shared.datatables.info_filtered"),
      "sInfoPostFix":    "",
      "sInfoThousands":  ",",
      "sLengthMenu":     I18n.t("shared.datatables.length_menu"),
      "sLoadingRecords": I18n.t("shared.datatables.loading_records"),
      "sProcessing":     I18n.t("shared.datatables.processing"),
      "sSearch":         I18n.t("shared.datatables.search") + ":",
      "sZeroRecords":    I18n.t("shared.datatables.zero_records"),
      "oPaginate": {
        "sFirst":    I18n.t("shared.datatables.first"),
        "sLast":     I18n.t("shared.datatables.last"),
        "sNext":     I18n.t("shared.datatables.next"),
        "sPrevious": I18n.t("shared.datatables.previous")
      },
      "oAria": {
        "sSortAscending":  I18n.t("shared.datatables.sort_ascending") + ": ",
        "sSortDescending": I18n.t("shared.datatables.sort_descending") + ": "
      }
    }
  });

  $('#logdata').DataTable({
    paging: true,
    ordering: true,
    lengthChange: false,
    displayLength: 20,
    dom: '<"toolbar">frtip',
    language: {
      "sEmptyTable":     I18n.t("shared.datatables.empty_table"),
      "sInfo":           I18n.t("shared.datatables.info"),
      "sInfoEmpty":      I18n.t("shared.datatables.info_empty"),
      "sInfoFiltered":   I18n.t("shared.datatables.info_filtered"),
      "sInfoPostFix":    "",
      "sInfoThousands":  ",",
      "sLengthMenu":     I18n.t("shared.datatables.length_menu"),
      "sLoadingRecords": I18n.t("shared.datatables.loading_records"),
      "sProcessing":     I18n.t("shared.datatables.processing"),
      "sSearch":         I18n.t("shared.datatables.search") + ":",
      "sZeroRecords":    I18n.t("shared.datatables.zero_records"),
      "oPaginate": {
        "sFirst":    I18n.t("shared.datatables.first"),
        "sLast":     I18n.t("shared.datatables.last"),
        "sNext":     I18n.t("shared.datatables.next"),
        "sPrevious": I18n.t("shared.datatables.previous")
      },
      "oAria": {
        "sSortAscending":  I18n.t("shared.datatables.sort_ascending") + ": ",
        "sSortDescending": I18n.t("shared.datatables.sort_descending") + ": "
      }
    }
  });
  $("div.toolbar").html('<label>' + I18n.t("calls.show.call_data_subheading") + ':</label>');

  $('#appDeleteModal').on('hidden.bs.modal', event => $('#appDeleteName').val(''));

  window.loadCustomTables = window.loadCustomTables || [];
  return $.each(window.loadCustomTables, (index, func) => func());
});

var deleteApplication = function(orgId, appId) {
  $(".content").spin();
  $.ajax({
    type: "DELETE",
    url: "/organizations/" + orgId + "/applications/" + appId,
    datatype: "json",
    async: true,
    success(data, textStatus, jqXHR) {
      $(".content").spin(false);
      return window.location.replace("/organizations/" + orgId + "/applications");
    },
    error(jqXHR, textStatus, errorThrown) {
      $("content").spin(false);
      return alert(errorThrown);
    }
  });
  return false;
};

var addNumber = function(orgId, region) {
  $(".content").spin();
  $.ajax({
      type: "POST",
      url:    "/organizations/" + orgId + "/add_number",
      datatype:"json",
      async:   true,
      data: {region, id: orgId},
      success(data, textStatus, jqXHR) {
        $(".content").spin(false);
        if (data.success) {
          return window.location.replace("/organizations/" + orgId + "/numbers");
        } else {
          return window.location.replace("/organizations/" + orgId + "/number/new?error=" + data.message);
        }
      },

      error(jqXHR, textStatus, errorThrown) {
        $(".content").spin(false);
        return alert(errorThrown);
      }
  });
  return false;
};

var provision_number = function(orgId, number) {
  $(".content").spin();
  $.ajax({
      type: "POST",
      url:    "/organizations/" + getOrgId() + "/provision_number",
      datatype:"json",
      async:   true,
      data: {number, id: orgId},
      success(data, textStatus, jqXHR) {
        $(".content").spin(false);
        if (data.success===true) {
          return window.location.replace("/organizations/" + orgId + "/numbers");
        } else {
          return alert(data.message);
        }
      },
      error(jqXHR, textStatus, errorThrown) {
        $(".content").spin(false);
        return alert(errorThrown);
      }
  });
  return false;
};

var addEntryPoint = function(orgId, room_id, room_name, bot_id) {
  $(".content").spin();
  $.ajax({
      type: "POST",
      url:    "/organizations/" + orgId + "/add_entry_point",
      datatype:"json",
      async:   true,
      data: {room_id, id: orgId, room_name, bot_id},
      success(data, textStatus, jqXHR) {
        $(".content").spin(false);
        return window.location.replace("/organizations/" + orgId + "/bots");
      },

      error(jqXHR, textStatus, errorThrown) {
        $(".content").spin(false);
        return alert(errorThrown);
      }
  });
  return false;
};

const customNumber = function(orgId, number) {
  if (number === "") {
    return $('#error_explanation').html('<p>' + I18n.t("organizations.shared.new_number_form.invalid_number_error_message") + '</p>');
  }

  $(".content").spin();
  $.ajax({
    type: "POST",
    url: "/organizations/" + orgId + "/custom_number",
    datatype: "json",
    async: true,
    data: {id: orgId, number},
    success(data, textStatus, jqXHR) {
      $(".content").spin(false);
      if (data.success) {
        return window.location.replace("/organizations/"  + orgId + "/numbers");
      } else {
        return alert(data.message);
      }
    },
    error(jqXHR, textStatus, errorThrown) {
      $(".content").spin(false);
      return alert(errorThrown);
    }
  });
  return false;
};

var sig_wire_pop = function(area_code) {
  $(".content").spin();
  const exchangeList = [];
  $.ajax({
    type: "POST",
    //url: "/signalwire/" + area_code + "/list_numbers"
    url: "/organizations/" + getOrgId() + "/list_numbers/" + area_code,
    datatype: "json",
    async:  true,
    success(data, textStatus, jqXHR) {
      $(".content").spin(false);
      for (let i = 0, end = data.countries.length, asc = 0 <= end; asc ? i < end : i > end; asc ? i++ : i--) {
        exchangeList.push({country: data.countries[i], prefix: data.prefixes[i], city: data.cities[i]});
      }
      console.log(window.exchangeList);
      return loadNumberList(exchangeList);
    },
    error(jqXHR, textStatus, errorThrown) {
      $(".content").spin(false);
      return alert(errorThrown);
    }
  });

  return false;
};


var getNumbers = function() {
  const exchangeList = [];
  return $.ajax({
    type: "POST",
    url: "/organizations/" + getOrgId() + "/list_numbers",
    datatype: "json",
    async: true,
    success(data, textStatus, jqXHR) {
      for (let i = 0, end = data.countries.length, asc = 0 <= end; asc ? i < end : i > end; asc ? i++ : i--) {
        exchangeList.push({country: data.countries[i], prefix: data.prefixes[i], city: data.cities[i]});
      }
      console.log(window.exchangeList);
      return loadNumberList(exchangeList);
    },
    error(jqXHR, textStatus, errorThrown) {
      return console.log(errorThrown);
    }
  });
};

var loadNumberList = function(list) {
  $('#region').empty();
  return $.each(list, (index, num) => $('#region').append('<option value="' + num.prefix + ':' + num.country + ':' + num.city + '">' + num.prefix + ' ' + num.city + '</option>'));
};

var getRegion = () => $("#region").find(":selected").val();

var getBot = () => $("#bot").find(":selected").val();

var getName = input => $("#" + input).html();

var getOrgId = () => $("input[type=hidden]#organization_id").val();

var getAppId = () => $("input[type=hidden]#application_id").val();

var getNumber = () => $("input[type=text]#customNumber").val();

var setVoiceLanguage = function(voiceLanguage) {
  let optionsAsString = "";
  return (() => {
    const result = [];
    for (var voice of Array.from(VOICES_BY_LANGUAGE[voiceLanguage])) {
      optionsAsString += `<option value='${voice}'>${voice}</option>`;
      $('#application_voice').html('');
      result.push($('#application_voice').append(optionsAsString));
    }
    return result;
  })();
};
    // setVoice VOICES_BY_LANGUAGE[voiceLanguage][0]

// setTimeZone = (timezone) ->
//   setProperty "timezone", timezone
//
// setVoice = (voice) ->
//   setProperty "voice", voice
//
// setLanguage = (language) ->
//   setProperty "language", language

const setProperty = function(property, value) {
  $(".content").spin();
  const data = { _method: 'PUT', application: {} };
  data.application[property] = value;
  console.log(`POST: ${JSON.stringify(data)}`);
  return $.ajax({
      type: "POST",
      url:  "/organizations/" + getOrgId() + "/applications/" + getAppId(),
      datatype: "json",
      async:   true,
      data,
      success(data, textStatus, jqXHR) {
        return $(".content").spin(false);
      },
      error(jqXHR, textStatus, errorThrown) {
        $(".content").spin(false);
        return alert(errorThrown);
      }
  });
};
