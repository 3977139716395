$(document).ready(function($) {

$('.up-button').click(function(){
  $('#partitions_selected_id option:selected:first-child').prop("selected", false);
  before = $('#partitions_selected_id option:selected:first').prev();
    $('#partitions_selected_id option:selected').detach().insertBefore(before);

});

$('.down-button').click(function(){
  $('#partitions_selected_id option:selected:last-child').prop("selected", false);
  after = $('#partitions_selected_id option:selected:last').next();
    $('#partitions_selected_id option:selected').detach().insertAfter(after);
});
 $('.left-button').click(function() {
  return !$('#skills_selected_id option:selected').remove().appendTo('#skills_available_id');
 });
 $('.right-button').click(function() {
  return !$('#skills_available_id option:selected').remove().appendTo('#skills_selected_id');
 });
 $( ".edit_agent" ).submit(function( event ) {
    allValues = $("#skills_selected_id option").map(function() {
           return this.value;
      }).get();
   $('#skill_members').val(allValues)

});
$( ".new_agent" ).submit(function( event ) {
   allValues = $("#skills_selected_id option").map(function() {
          return this.value;
     }).get();
  $('#skill_members').val(allValues)

});
});
