$(document).ready(function() {
  var tabLink = $('[data-behavior="switch-tab"]');
  var tabImage = $('[data-structure="tab-image"]');
  
  tabLink.click(function(event) {
    var target = $(this).attr('data-target');
    tabLink.removeClass('active');
    $(this).addClass('active');
    tabImage.removeClass('active');
    $('[data-tab-target="' + target + '"]').addClass('active');
    event.preventDefault();
  });
});