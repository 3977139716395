$(document).ready(function(){
    var initEmail = $('#userEmail').val();
    console.log(initEmail);
    $('.togglePassword').on('change textInput input', function(event){
        console.log('toggle');
        $('.currPass').show();
        $(event.target).on('change textInput input', function(remEvent){
            if($(remEvent.target).hasClass('email') && remEvent.target.value == initEmail){
                $('.currPass').hide();
            }
            else if(remEvent.target.value === ""){
                $('.currPass').hide();
            }
        });
    });
});
