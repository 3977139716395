/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
class Page {
  controller() { 
    return $('meta[name=psj]').attr('controller');
  }

  action() { 
    return $('meta[name=psj]').attr('action');
  }
}

this.page = new Page;
