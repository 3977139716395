$(document).ready( function(){
  $('[data-behavior="user-menu"]').click( function(){
    var menu = $('.user-menu');
    if( menu.is(":visible")) {
      menu.hide();
    } else {
      menu.show();
    }
  });
});
